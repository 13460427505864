import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/portfolio-2.png";
import projImg2 from "../assets/img/portfolio-1.png";
import projImg3 from "../assets/img/portfolio-5.png";
import projImg4 from "../assets/img/portfolio-6.png";
import projImg5 from "../assets/img/portfolio-8.png";
import projImg6 from "../assets/img/portfolio-10.png";
import projImg7 from "../assets/img/portfolio-3.png";
import projImg8 from "../assets/img/portfolio-4.png";

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Hello-Sister.de",
      description: "This business startup primarily focuses on the healthcare domain and aims to provide a platform for connecting employers with nurses for recruitment.",
      imgUrl: projImg1,
    },
    {
      title: "Hello-Doctor.de",
      description: "This startup is utilizes a variety of technologies to create a comprehensive web application for doctors.",
      imgUrl: projImg2,
    },
    {
      title: "Tsar Host",
      description: "I had the opportunity to work on a freelance project involving the development of the frontend for the Tsarhost.com hosting website.",
      imgUrl: projImg3,
    },
    {
      title: "Business Startup",
      description: "The objective of this project was to create a professional and user-friendly website that effectively showcased the technical services offered by Alabru Technical Services.",
      imgUrl: projImg4,
    },
    {
      title: "eatmostlygreens",
      description: "The objective of this project was to create an engaging and visually appealing website that showcased the beauty and services of eatmostlygreens.",
      imgUrl: projImg5,
    },
    {
      title: "Win-Consultants",
      description: "I had the opportunity to develop a website for win-consultants.com using WordPress.",
      imgUrl: projImg6,
    },
  ];

  const mobileApps = [

    {
      title: "Hello-Doctor.de (Mobile App for IOS & Android)",
      description: "This startup is utilizes a variety of technologies to create a comprehensive web application for doctors.",
      imgUrl: projImg8,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Projects</h2>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Web</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Mobile Apps</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Technologies</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {
                            projects.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {
                            mobileApps.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>                    </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>
                          HTML,
                          CSS/SCSS
                          ,
                          React Native
                          ,
                          Javascript
                          ,
                          React
                          ,
                          Wordpress
                          ,
                          Next.js
                          ,
                          CI/CD
                          ,
                          TailwindCSS
                          ,
                          Nodejs/Express
                          ,
                          Amazon Web Services (AWS)
                          ,
                          Typescript
                         

                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
